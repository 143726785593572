/* FooterBottom styles */
.root {
  @apply bg-[#0E0E23] text-white z-100;
  @apply font-medium;
}

.separator {
  @apply pt-6 lg:pt-10;
}

.copy {
  @apply text-white/[0.3];

  @apply text-sm py-6 px-4 lg:px-0 text-center lg:text-left;
}

.navigation {
  grid-template-areas: 'column-1 column-2';

  @apply flex-grow px-0 py-10;
  @apply grid grid-cols-2 lg:grid-cols-5 gap-4;
}

.navCell {
  @apply mb-4 sm:mb-0 flex flex-col;

  &.col1 {
    grid-area: column-1;
  }

  &.col2 {
    grid-area: column-2;
  }
}

.navContentAndMobileCell,
.navSupportCell {
  @apply order-1 md:order-none;
}

.navBlock {
  @apply mt-8;

  &:first-child {
    @apply mt-0;
  }
}

.navContentBlock {
  @apply order-1 md:order-none mt-0 mb-8 md:mt-8 md:mb-0;
}

.navMobileBlock {
  @apply order-2 md:order-none mt-0 md:mt-8;
}

.navOthersBlock {
  @apply mt-20;
}

.mobileBadge {
  @apply mt-3;

  img {
    @apply m-0 block;
  }

  &:first-child {
    @apply mt-0;
  }
}

.mobileBadgeTitle {
  @apply mb-7;
}

.mobile {
  @apply block lg:hidden;
}

.desktop {
  @apply hidden lg:block;
}
