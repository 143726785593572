.row {
  @apply flex flex-row justify-between -mx-2.5 -my-3 -mb-4 flex-wrap;
  @apply sm:-mb-7;
}

.item {
  @apply w-1/2 px-2 mt-3 mb-4 flex flex-col;
  @apply sm:w-1/3 sm:mb-7;
}

.itemLabel {
  @apply text-white/[0.6];
  @apply text-sm whitespace-nowrap text-ellipsis overflow-hidden block;
}

.itemValue {
  @apply text-base mt-4 sm:mt-5;
}

.diffChange {
  @apply mx-0.5;
}

.plus {
  @apply text-green-400;
}
