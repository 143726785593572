@import '@/shared/styles/abstract/settings';

/* Banner styles */
.root {
  @apply relative z-40;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 640px;
    max-width: 100%;
    z-index: 50;
    border: 1px solid rgba(255,255,255,0.2);

    @apply bg-[#000018] mx-auto text-base sm:text-xl my-8 sm:my-16 leading-6 p-8 rounded-xl;

    @media (max-width: 640px) {
      top: -40px;
    }
  }

  .link {
    color: #FFA800;
  }

  .title {
    color: var(--hos-color-gray-065);

    @apply text-xl font-medium leading-6 mb-5;

    &.titleBig {
      @apply text-3xl leading-9;
      color: #FFA800;
    }
  }

  .ul {
    @apply list-disc pl-4;
  }

  .ol {
    @apply list-decimal pl-4;
  }

  .listItem {
    @apply text-base leading-6;
  }

  .listItem:not(:last-child) {
    @apply mb-3;
  }

  * {
    box-sizing: border-box;
  }
}

.addressMobileGap {
  @media (max-width: 640px) {
    @apply mt-[360px];
  }
}