/* Footer social styles */
.root {
  margin-top: -1rem;
}

.social {
  @apply flex items-center justify-center lg:justify-start px-4 lg:px-0 lg:pl-4;
}

.filler {
  pointer-events: none;
}

.socialItem {
  @apply text-white/[0.6];
  line-height: 0;

  @apply ml-6 transition duration-300 text-xl;

  img {
    @apply m-0 block;
  }

  &:hover,
  &:focus {
    color: var(--hos-color-grey-049);
  }

  &:first-child {
    @apply ml-0;
  }
}

@media screen and (min-width: 1040px) {
  .root {
    margin-top: -4.3rem;
    display: grid;
    grid-template-columns: 3fr 2fr;
    width: 100%;
  }
}
