/* Table styles */
.root {
  @apply p-4 sm:p-8 overflow-x-auto lg:mx-auto lg:w-5/6 rounded-xl bg-[#0E0E23];
  @apply border border-solid;

  border-color: rgba(255, 255, 255, 0.1);
}

.table {
  min-width: 500px;

  @apply w-full;

  thead {
    @apply text-sm text-gray-065;
  }

  th,
  td {
    @apply text-left font-normal;

    &:not(:last-child):not(:first-child) {
      @apply px-3 sm:px-4;
    }

    &:first-child {
      @apply pr-3 sm:pr-4;
    }

    &:last-child {
      @apply pl-3 sm:pl-4 sticky sm:static -right-4 bg-[#0E0E23] sm:bg-transparent;
    }
  }

  th {
    @apply py-4;
  }

  td {
    @apply py-4;
  }

  tbody { @apply md:text-xl; }

  tr:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.more {
  @apply text-amber-050 text-base;
}
