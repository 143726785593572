.list {
  @apply flex flex-wrap sm:flex-nowrap justify-between items-center py-5 px-4 relative rounded-xl bg-white/[0.04];
  @apply border border-solid border-white/[0.1];
  @apply sm:p-5;
}

.item {
  @apply border-b border-r border-solid border-white/[0.1];

  @apply w-1/2 py-5 px-2 flex flex-col items-center relative;
  @apply sm:py-0 sm:px-0 sm:border-r-0 sm:border-b-0;


  &:nth-child(2n) { @apply border-r-0; }
  &:nth-last-child(-n+2) { @apply border-b-0; }

  &:not(:last-child)::after {
    content: '';

    @apply w-[1px] absolute right-0 top-0 bottom-0 hidden sm:block bg-white/[0.1];
  }
}

.itemLabel {
  letter-spacing: 0.25px;

  @apply text-sm block text-white/[0.6];
}

.itemValue {
  @apply font-medium inline-block sm:text-2xl mt-2 sm:mt-5;
}
