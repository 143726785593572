/* AddressExplorer styles */
.root {
  @apply relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #14142b inset !important;
  }

  [data-radix-popper-content-wrapper] {
    max-width: 800px;
    width: 100%;
  }
}

.inputGroup {
  transition: border 0.15s linear;

  @apply relative border border-solid border-white/[0.1] bg-white/[0.08];
  @apply rounded-xl flex w-full justify-between border-r-0;

  &:hover {
    @apply border-white/[0.4];

    input {
      @apply border-white/[0.4];
    }
  }

  &:focus-within {
    @apply border-white/[0.6];

    input {
      @apply border-l-white/[0.4];
    }
  }
}

.input {
  @apply appearance-none bg-transparent px-4 py-5 flex-grow w-full outline-none text-white;
  @apply border-l border-solid border-l-white/[0.1];
}

.button {
  background-image: linear-gradient(180deg, rgba(125, 138, 254, 0.17) 0%, rgba(32, 32, 32, 0) 100%);

  @apply absolute -right-1 -top-[1px] -bottom-[1px] w-[60px] sm:w-full;
}