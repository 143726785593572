/* TutorialLink styles */
.btn {
  transition: all 0.15s linear;

  @apply text-xl text-center inline-block no-underline text-[#464E59];

  &:hover,
  &:focus {
    @apply no-underline text-white;
  }
}

.btnIcon {
  @apply w-[145px] h-[164px] mb-4 relative block;

  @at-root {
    .btn:hover,
    .btn:focus {
      .btnIcon::after {
        @apply opacity-100;
      }
    }
  }

  &::before,
  &::after {
    content: '';
    transition: all 0.3s linear;

    @apply absolute top-0 right-0 bottom-0 left-0 bg-contain;
  }
  &::after {
    @apply z-10 opacity-0;
  }

  &.tutorial {
    &::before { background: url('/assets/images/tutorial-icon.svg') 50% 0 no-repeat; }
    &::after { background: url('/assets/images/tutorial-icon-hover.svg') 50% 0 no-repeat; }
  }
  &.video {
    &::before { background: url('/assets/images/tutorial-video-icon.svg') 50% 0 no-repeat; }
    &::after { background: url('/assets/images/tutorial-video-icon-hover.svg') 50% 0 no-repeat; }
  }
}
