@import '@/shared/styles/abstract/settings';

.root {
  @apply relative;
}

.light {
  z-index: -1;
  opacity: 0.7;

  @apply absolute;
}

.abstractLight1 {
  background: url('/assets/images/abstract-shape-1.svg') 50% 50% no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
  width: 200px;
  height: 200px;

  @media #{$min-media-sm} {
    width: 472px;
    height: 427px;
  }
}

.abstractLight2 {
  background: url('/assets/images/abstract-shape-2.svg') 50% 50% no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
  width: 150px;
  height: 203px;

  @media #{$min-media-sm} {
    width: 298px;
    height: 310px;
  }
}