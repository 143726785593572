///* Button styles */
$border: 2px;

.button {
  @apply inline-block bg-transparent box-border border-none relative no-underline rounded-2xl;
  @apply cursor-pointer whitespace-normal appearance-none select-none;
  @apply text-white;
  @apply bg-[#000018];
  line-height: 1.5;
  background-clip: padding-box;
  border: solid $border transparent;
  
  // #000018
  -webkit-touch-callout: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;

  &.primary {
    &::before,
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      transition: opacity 0.3s ease-in-out;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      margin: -$border;
      border-radius: inherit;

      @media not all and (min-resolution: 0.001dpcm) {
        transition: none;
      }
    }

    &:hover,
    &:focus {
      @apply outline-none no-underline text-white;

      &::after {
        opacity: 1;
      }

      .btn-shadow {
        background: linear-gradient(262.77deg, #06b4ff 0.86%, #f98f13 100.31%);
        bottom: -5px;
      }
    }
    &::before {
      background: linear-gradient(262.77deg, #ffeb06 0.86%, #fe5400 100.31%);
    }
    &::after {
      background: linear-gradient(262.77deg, #06b4ff 0.86%, #f98f13 100.31%);
      opacity: 0;
    }
    
  }
}

.inner {
  @apply flex justify-center items-center text-center px-3 font-sans font-semibold;
  @apply box-border border border-solid border-transparent rounded-md transition text-xs relative;
  &.xs {
    min-height: 24px;
  }
  &.sm {
    min-height: 30px;
  }
  &.xl {
    @apply text-xl px-7 py-2.5;
  }
  &.isIconBtn {
    &.prefix {
      @apply mr-0;
    }
    &.suffix {
      @apply ml-0;
    }
    &.sm {
      @apply px-[3px]
    }
    &.xs {
      @apply px-[1px]
    }
  }
}

.prefix {
  @apply block mr-[6px];

  & > figure {
    @apply block;
  }
}

.suffix {
  @apply block ml-[6px];
  & > figure {
    @apply block;
  }
}

.prefixIcon,
.iconRight {
  @apply shrink-0;
}

.loadingDots {
  @apply flex items-center justify-center;

  height: 1rem;
}


