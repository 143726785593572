/* ProfitCalculator styles */
.root {}

.row {
  @apply flex flex-col justify-between;
  @apply md:flex-wrap md:flex-row md:items-end;
  @apply lg:flex-nowrap;
}

.col {
  @apply flex items-center justify-between flex-grow mb-8 lg:mb-0 lg:w-1/3 lg:max-w-[50%];

  &.compact {
    @apply flex-grow-0 sm:max-w-[160px];
  }

  &:last-child {
    @apply justify-end w-full lg:w-1/4;
  }
}

.formGroup {
  @apply w-full relative;
}

.separator {
  @apply absolute top-full left-0 right-0 mx-auto mt-6 w-10 text-center;
  @apply md:mx-7 md:mt-0 md:static md:w-auto;
}

.calcProfit {
  @apply whitespace-nowrap pt-5 pb-3 md:pb-5 mr-auto inline-block relative;
  @apply md:mx-7;
}

.inputLabel {
  @apply mb-5 block text-white/[0.6] text-base;
}

.inputGroup {
  transition: border 0.15s linear, box-shadow 0.15s linear;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.08);

  @apply rounded-xl flex w-full justify-between;

  &:hover {
    border-color: rgba(255, 255, 255, 0.4);

    .inputGroupText {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }

  &:focus-within {
    border-color: rgba(255, 255, 255, 0.6);

    .inputGroupText {
      border-color: rgba(255, 255, 255, 0.6);
    }
  }
}

.inputGroupText {
  transition: border 0.15s linear;
  @apply flex items-center px-4 whitespace-nowrap;

  &.prefix ,&.suffix {
    background-color: rgba(255, 255, 255, 0.04);
  }

  &.prefix {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
  }
  &.suffix {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
}

.inputControl {
  @apply bg-transparent border-none appearance-none px-4 py-5 flex-grow w-full outline-none text-white align-middle;
}

.selectControl {
  > div {
    @apply box-content w-full;
  }

  > div span + div {
    @apply px-3 py-4 box-content;
  }
}

.toggleBtn {
  transition: all 0.3s linear;
  background-image: linear-gradient(180deg, rgba(125, 138, 254, 0.17) 0%, rgba(32, 32, 32, 0) 100%);

  @apply relative px-5 py-5 rounded-xl font-bold appearance-none bg-transparent w-full text-white;
  @apply cursor-pointer whitespace-normal select-none;
  @apply text-white bg-[#000018] bg-clip-padding border border-solid border-transparent;
  @apply lg:w-auto;

  -webkit-touch-callout: none;
  outline: none;
  font-size: inherit;
  font-weight: inherit;

  &[aria-expanded='true'] {
    .toggleArrow {
      transform: rotate(0deg);
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    transition: opacity 0.3s ease-in-out;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;

    @media not all and (min-resolution: 0.001dpcm) {
      transition: none;
    }
  }
}

.toggleArrow {
  transition: transform 0.15s linear;
  will-change: transform;
  transform: rotate(180deg);

  @apply ml-3 inline-block;
}