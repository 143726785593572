/* ScrollToGame styles */
.root {
  transform: translateY(-50%);
  right: 20px;

  @apply flex flex-col gap-1 items-center fixed text-center z-100 p-0 m-0 appearance-none bg-transparent border-none top-1/2;

}

.gameIcon {
  background: url('/assets/games/game-icon-flappy_bird.png') 50% 50% / contain no-repeat;

  @apply w-16 h-16 block mx-auto;
}

.gameLabel {
  @apply block font-bold text-xs text-white;
}

.scrollArrow {
  color: #f0513a;
}
