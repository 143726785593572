.root {
  @apply fixed z-40 w-full h-full inset-0;

  .content {
    border: 1px solid rgba(255,255,255,0.2);

    @apply absolute top-[80px] left-0 right-0 bottom-0 max-w-[640px] w-full bg-[#000018] max-h-[800px] mx-auto text-base sm:text-xl my-8 sm:my-16 leading-6 p-8 rounded-xl z-50 overflow-auto;
  }

  &::before {
    content: '';

    @apply absolute inset-0 bg-black/[0.60] z-40;
  }

  .link {
    @apply text-[#FFA800];
  }

  .title {
    color: var(--hos-color-gray-065);

    @apply text-xl font-medium leading-5 mb-5;

    &.titleBig {
      @apply text-3xl leading-9;
      color: #FFA800;
    }
  }

  .ul {
    @apply list-disc pl-4;
  }

  .ol {
    @apply list-decimal pl-4;
  }

  .listItem {
    @apply text-base leading-6;
  }

  .listItem:not(:last-child) {
    @apply mb-3;
  }

  * {
    box-sizing: border-box;
  }
}

.addressMobileGap {
  @media (max-width: 640px) {
    @apply mt-[360px];
  }
}