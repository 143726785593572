/* SelectSubaccount styles */

.listTitle {
  @apply text-xs font-medium text-white/[0.6] mt-2 mb-1 p-1 w-full;
}

.listContainer {
  @apply overflow-auto max-h-[170px];
  &::-webkit-scrollbar{
    display: none;
  }

  @media (min-width: 640px) {
    @apply max-h-none;
  }
}

.list {
  @apply mt-1 p-1 rounded-xl backdrop-blur-lg z-[101] absolute right-[-4px] sm:left-[124px];

  scroll-behavior: smooth;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.45);
  outline: none;
}

.item {
  @apply w-full px-3 pt-[9px] pb-[9px] cursor-pointer leading-3 text-xs rounded-sm flex;
  @apply items-center;
  @apply outline-none;


  .arrowTo {
    @apply opacity-100 sm:opacity-0;
  }

  &:hover {
    background: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);

    .arrowTo {
      @apply opacity-100;
    }
  }

  &:focus, &:active {
    background-image: linear-gradient(0deg, rgba(0, 0, 24, 0.1), rgba(0, 0, 24, 0.1)), linear-gradient(180deg, #F47D02 0%, #FB6101 100%);

    .arrowTo {
      @apply opacity-100;
    }
  }

  &.touchDevice {
    @apply text-base pt-[12px] pb-[12px];

    .arrowTo {
      @apply opacity-100;
    }
  }
}

.item[data-selected],
.item.active {
  background: linear-gradient(180deg, #F47D02 0%, #FB6101 100%);
}
