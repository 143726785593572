@import '@/shared/styles/abstract/settings';

.subTitle {
  @apply font-bold my-8 leading-5;
  @apply md:mt-24 md:mb-28 md:text-center md:text-4/25xl md:leading-9;
}

.steps {
  @apply flex flex-col mb-8 sm:mb-20;
  @apply md:flex-row md:justify-between md:-mx-12;
}

.step {
  background: rgba(37, 37, 37, 0.2);

  @apply flex my-2 rounded-2xl text-sm py-3 px-4 relative bg-no-repeat bg-cover;
  @apply md:w-1/3 items-center md:mx-12 md:my-0 md:text-xl border border-solid border-white/[0.1];

  @media #{$min-media-md} {
    min-height: 88px;
  }

  &:not(:first-child)::before {
    content: '';

    @apply w-[1px] absolute h-4 -top-4 left-1/2;
    @apply md:w-24 md:right-full md:top-1/2 md:left-auto bg-white/[0.1];

    @media #{$min-media-md} {
      @apply h-[1px];
    }
  }
}

.stepBg1 {
  @media (min-width: 1024px) {
    background-image: url('/assets/images/step-bg-1.jpg');
  }
}

.stepBg2 {
  @media (min-width: 1024px) {
    background-image: url('/assets/images/step-bg-2.jpg');
  }
}

.stepBg3 {
  @media (min-width: 1024px) {
    background-image: url('/assets/images/step-bg-3.jpg');
  }
}

.stepCounter {
  @apply relative rounded overflow-hidden mr-2 inline-flex items-center justify-center text-xs font-bold leading-7;
  @apply w-[28px] h-[28px] flex-shrink-0 rounded-full bg-no-repeat;

  background-image: url('/assets/images/step-count-gradient.svg');
}

.imageShift {
  @apply lg:w-5/6 mx-auto -mt-5 md:mt-0;
}

.image {
  @apply block mb-12 text-center;
}

.modalContent {
  @apply p-0 #{!important};
}

.closeBtn {
  @apply top-1 right-1 #{!important};
}