/* Footer styles */
.title {
  color: var(--hos-color-gray-065);

  @apply text-xl font-medium leading-5 mb-5;
}

.item:not(:last-child) {
  @apply mb-3;
}

.link {
  @apply text-white/[0.6];

  @apply text-base inline-block font-normal transition duration-300 leading-5 no-underline;

  &:hover,
  &:focus {
    color: var(--hos-color-gray-094);

    @apply no-underline;
  }
}
