@import '@/shared/styles/abstract/settings';

/* Banner styles */
.root {
  @apply relative z-10;

  &::before {
    content: '';
    position: absolute;
    top: -30px;
    left: 0;
    right: 0;
    bottom: -120px;
    background-color: rgba(0,0,0,0.6);
    z-index: 1000;
  }

  * {
    box-sizing: border-box;
  }
}