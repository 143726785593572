@import '@/shared/styles/abstract/settings';

/* HowItWorks styles */
.root {
  * {
    box-sizing: border-box;
  }
}

.abstract1 {
  background: url('/assets/images/abstract-1.svg') 50% 50% no-repeat;
  width: 422px;
  height: 208px;
  z-index: -1;

  @apply bg-cover;
}

.cards {
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));

  @apply grid gap-6 md:gap-10 lg:gap-14;
}

.listTitle {
  @apply text-2xl lg:text-4xl font-bold mb-6 leading-9 max-w-[680px] mt-14 md:mt-28;
}

.list {
  @apply max-w-[680px] mx-auto;
}

.listItem {
  @apply relative text-white/[0.6] text-base leading-5 pl-8 last:mb-0 lg:pb-8 pb-4;

  &.connector {
    &:after {
      background: linear-gradient(180deg, #0F87F8 0%, #2AD3EA 100%);
      box-shadow: 0 0 6px 0 rgba(49, 233, 230, 0.60);

      @apply content-[''] absolute left-[14px] top-[19px] bottom-[-4px] w-[1px];
    }
  }
}

.listIcon {
  @apply absolute left-0 -mt-1;
}