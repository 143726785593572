/* Search styles */
.root {
  width: 120px;

  a {
    @apply block;
  }

  img {
    width: 100%;
  }
}

