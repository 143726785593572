@import '@/shared/styles/abstract/settings';

.root {
  @apply relative;
}

.light {
  z-index: -1;
  opacity: 0.7;

  @apply absolute;
}

.abstractLight1 {
  background: url('/assets/images/abstract-shape-1.svg') 50% 50% no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
  width: 200px;
  height: 200px;

  @media #{$min-media-sm} {
    width: 472px;
    height: 427px;
  }
}

.abstractLight2 {
  background: url('/assets/images/abstract-shape-2.svg') 50% 50% no-repeat;
  background-size: cover;
  mix-blend-mode: color-dodge;
  width: 150px;
  height: 203px;

  @media #{$min-media-sm} {
    width: 298px;
    height: 310px;
  }
}

.listWrapper {
  @apply md:max-w-[310px] min-w-[280px];
}

.listTitle {
  @apply text-2xl lg:text-4xl font-bold mb-4 leading-9;

  span {
    background-image: linear-gradient(180deg, #31E9E6 0%, #0873FB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.listItem {
  @apply relative text-white text-base leading-5 pl-7 last:mb-0 lg:mb-8 mb-4;
}

.listIcon {
  @apply absolute left-0 mt-1;
}