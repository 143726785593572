/* AdvancedCalculator styles */
.root {
  @apply my-6 sm:my-12;
}

.head {
  @apply mb-6 flex flex-col sm:flex-row items-baseline justify-center sm:justify-start;
}

.headDNX {
  @apply flex flex-col max-w-[50%];
}

.title {
  @apply text-4xl font-bold text-center sm:text-left mb-4 leading-9;
}

.desc {
  @apply text-white/[0.6] text-base leading-5;
}

.linkQuestion {
  @apply mt-2 sm:mt-0 text-sm sm:ml-3 inline-block text-white/[0.6];

  &:hover {
    @apply text-[#4FA8E3];
  }
}

.calc {
  @apply flex flex-col my-6;
  @apply md:my-12;
  @apply lg:flex-row;
}

.settings {
  border-color: var(--hos-color-gray-021);

  @apply lg:pr-5 pb-5 lg:pb-0 lg:border-r lg:border-solid flex-1;
}

.inputRow {
  @apply flex mb-5 sm:mb-7;

  &.hardware {
    @apply items-end relative z-10;
  }

  &.units {
    z-index: 5;

    @apply mb-0 sm:mb-7 flex-col sm:flex-row items-start sm:items-center relative;
  }
}

.inputGroup {
  transition: border 0.15s linear, box-shadow 0.15s linear;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.08);

  @apply rounded-xl flex w-full justify-between;

  &:hover {
    border-color: rgba(255, 255, 255, 0.4);

    .inputGroupText {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }

  &:focus-within {
    border-color: rgba(255, 255, 255, 0.6);

    .inputGroupText {
      border-color: rgba(255, 255, 255, 0.6);
    }
  }
}

.inputGroupText {
  transition: border 0.15s linear;

  @apply px-4 py-5 whitespace-nowrap;
}

.inputControl {
  @apply bg-transparent border-none appearance-none px-4 py-5 flex-grow w-full outline-none text-white;
}

.selectControl {
  > div {
    @apply box-content w-full;
  }

  > div span + div {
    @apply px-3 py-4 box-content;
  }
}

.unitItem {
  @apply w-full sm:w-auto mb-5 sm:mb-0 relative;

  &:first-child { z-index: 3; }
  &:nth-child(2) { z-index: 2; }
  &:nth-child(3) { z-index: 1; }

  &:not(:first-child) {
    @apply sm:ml-5;
  }
}

.unitLabel {
  > span {
    color: var(--hos-color-gray-065);

    @apply text-base mb-4;
  }

  > span > span {
    color: var(--hos-color-gray-065);
  }
}

.unitItemControls {
  @apply flex items-center;
}

.hardwareControl {
  @apply py-0.5;
}

.selectControl,
.hardwareControl {
  > div {
    @apply box-content w-full;
  }

  > div span + div {
    @apply px-3 py-4 box-content;
  }
}

.selectControl {
  min-width: 90px;

  @apply p-0;

  &.hashrate {
    @apply w-16;
  }
}

.inputWrap {
  @apply flex-1 sm:flex-none;
}

.hashrateInput {
  @apply w-full sm:w-20;
}

.powerInput {
  @apply w-full sm:w-20;
}

.powerPriceInput {
  @apply w-full sm:w-20;
}

.actions {
  @apply mt-5;
}


