/* TableResult styles */

.results {
  @apply mt-9 sm:mt-0 sm:pl-5 flex-1;
}

.table {
  @apply text-white/[0.6] text-center w-full mb-4 sm:table-fixed overflow-hidden;

  col {
    &:first-child {
      @apply w-10 sm:w-auto;
    }
  }
}

.tableHead {
  tr {
    @apply border-b border-solid border-white/[0.1];
  }

  th {
    @apply border-l border-solid border-white/[0.1] text-center font-normal;
    @apply py-2 px-2 sm:px-4;

    &:first-child {
      @apply border-none text-left;
    }
  }
}

.tableBody {
  @apply bg-transparent;
  tr {
    @apply border-b border-solid border-white/[0.1];

    &:last-child {
      @apply border-none;
    }
  }

  td {
    @apply border-l border-solid align-middle border-white/[0.1];
    @apply py-3 px-2 sm:px-4;

    &:first-child {
      @apply border-none;
      @apply text-left;
    }
  }
}

.value {
  @apply text-base flex justify-center items-center flex-wrap font-bold;

  &.fiat {
    @apply block mb-2;
  }

  &.crypto {
    @apply flex items-center justify-center gap-1 w-full text-white;

    .icon {
      @apply relative -top-px;
    }
  }
}

.note {
  @apply text-sm leading-5 my-4 sm:my-8 text-[#6E7C8C];
}