/* Accordion styles */

.accordionItem {
  @apply mt-px overflow-hidden first:mt-0 first:rounded-t last:rounded-b;
}

.accordionTrigger {
  @apply relative inline-flex text-left;
  @apply text-white font-medium text-xl cursor-pointer;
  @apply bg-transparent border-0 pl-9 pr-0;

  &:focus, &:hover {
    @apply outline-none text-white;
  }

  &::before {
    content: '+';

    @apply absolute left-1 mt-2 leading-normal;
  }
}

.accordionTrigger[data-state='open'] {
  @apply text-white;

  &::before {
    content: '-';
  }
}

.accordionContent {
  @apply overflow-hidden outline-none text-base leading-5 text-white/[0.60] font-medium pl-9 pr-4;
}

.accordionContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}

.accordionContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}