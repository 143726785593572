/* ChartTooltip styles */
.root {
  background-color: rgba(#16181d, 0.9);

  @apply px-2 py-3 outline-none;
}

.tooltipItem {
  @apply text-sm mt-2 font-normal;

  &:first-child {
    @apply mt-0;
  }
}

.tooltipLabel {
  @apply text-gray-50;
}
