@import '@/shared/styles/abstract/settings';

/* PoolAdvantages styles */
.root {
  * {
    box-sizing: border-box;
  }
}

.blocks {
  @apply my-8 md:my-20 lg:my-28 relative;
}

.blocksGrid {
  @apply flex flex-col sm:flex-row -mx-3 items-stretch;
}

.blocksCol {
  @apply flex flex-col mx-3 sm:w-1/2;
}

.blockWrap {
  @apply sm:flex mb-4 sm:mb-6 relative grow;
}

.block {
  background: rgba(37, 37, 37, 0.2);
  border: 1px solid #2c3047;
  backdrop-filter: blur(100px);

  @apply w-full p-6 flex flex-col justify-center rounded-2xl;
  @apply md:p-9;

  &.blockRow { @apply xl:flex-row flex-wrap xl:justify-start; }
}

.blockBg1 {
  background-image: url('/assets/images/advantages-bg-gram.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.blockBg2 {
  background-image: url('/assets/images/advantages-bg-gram-1.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.blockBg3 {
  background-image: url('/assets/images/advantages-bg-gram-2.png');
  background-size: cover;
  background-repeat: no-repeat;
}
.blockBg4 {
  background-image: url('/assets/images/advantages-bg-gram-3.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.blockHover {
  transition: transform 0.3s linear, opacity 0.3s linear, height 0.3s linear;
  max-width: 450px;
  transform: translateY(-55%);
  will-change: opacity, transform;

  @apply opacity-0 h-0 overflow-hidden static invisible rounded-xl text-sm z-100 w-full leading-5;
  @apply sm:w-auto sm:h-auto sm:absolute sm:text-xl sm:leading-7;

  @media #{$min-media-sm} {
    background: rgba(32, 32, 32, 0.6);
    border: 1px solid #363d45;
    backdrop-filter: blur(20px);
  }

  @at-root {
    .blockWrap:hover,
    .blockWrap:focus {
      .blockHover {
        transform: translateY(0);

        @media #{$min-media-sm} {
          transform: translateY(-50%);
        }

        @apply opacity-100 p-5 visible h-auto;
      }
    }
  }
}

.title1 { @apply text-3xl md:text-7/5xl font-bold; }
.title2 { @apply text-sm md:text-4/25xl md:font-semibold; }
.title3 { @apply text-3xl md:text-5xl font-bold; }
.title4 { @apply text-sm md:text-2xl md:font-semibold; }
.title5 { @apply font-bold text-3xl md:text-8xl; }
.title6 { @apply text-3xl md:text-7xl font-bold; }
.title7 { @apply text-5xl md:text-8/75xl; }
.title8 { @apply text-3xl md:text-5xl; }
.title9 { @apply text-3xl md:text-4/25xl; }
.title10 { @apply text-3xl md:text-8/75xl font-bold; }
.title11 { @apply text-sm md:font-semibold md:text-4xl; }

[lang=ru],
[lang=zh] {
  .title1 { @apply text-3xl md:text-7/5xl font-bold; }
  .title2 { @apply text-sm md:text-xl md:font-semibold; }
  .title3 { @apply text-3xl md:text-4xl font-bold; }
  .title4 { @apply text-sm md:text-xl md:font-semibold; }
  .title5 { @apply font-bold text-3xl md:text-7xl; }
  .title6 { @apply text-3xl md:text-5xl font-bold; }
  .title7 { @apply text-7xl md:text-8/75xl; }
  .title8 { @apply text-3xl md:text-5xl; }
  .title9 { @apply text-3xl md:text-4/25xl; }
  .title10 { @apply text-3xl md:text-8/75xl font-bold; }
  .title11 { @apply text-sm md:text-xl md:font-semibold; }
}

.separator::after {
  content: '';
  background: rgba(255, 255, 255, 0.1);
  width: 1px;

  @apply absolute h-full left-1/2;
}

.blockHoverPos1 {
  @apply right-0 sm:-right-10;
}

.blockHoverPos2 {
  @apply left-0 sm:-left-10;
}

.blockHoverPos3 {
  @apply left-0 sm:left-0 sm:right-0 sm:mx-auto;
}

.abstract1 {
  background: url('/assets/images/abstract-1.svg') 50% 50% no-repeat;
  width: 422px;
  height: 208px;
  z-index: -1;

  @apply hidden lg:inline-block absolute bg-cover;
}

.abstract2 {
  background: url('/assets/images/abstract-2.svg') 50% 50% no-repeat;
  width: 314px;
  height: 26px;
  z-index: -1;

  @apply hidden lg:inline-block absolute bg-cover;
}

/* Media query */
@media #{$min-media-sm} {
  .blockmH160 { min-height: 160px; }
  .blockmH210 { min-height: 210px; }

  .blockmHLang { min-height: 210px; }

  [lang='ru'] {
    .blockmHLang { min-height: 240px; }
  }

  .blockmH280 { min-height: 280px; }

  .blockmH360 {
    min-height: 170px;
  }

  .blockmH320 {
    min-height: 160px;
  }

  .blockmH220 {
    min-height: 160px;
  }

  .blockmH240 {
    min-height: 160px;
  }
}

@media #{$min-media-md} {
  .blockmH360 {
    min-height: 360px;
  }

  .blockmH320 {
    min-height: 320px;
  }

  .blockmH220 {
    min-height: 220px;
  }

  .blockmH240 {
    min-height: 240px;
  }
}
