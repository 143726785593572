/* HardwareCounter styles */

.root {
  @apply flex items-center justify-between max-w-[152px] rounded-xl w-full bg-white/[0.08];

  & > button[name="prefix"], & > button[name="suffix"] {
    @apply border-none outline-none h-[58px];
    @apply px-4 py-5 whitespace-nowrap bg-white/[0.04] w-[44px] relative appearance-none;

    background-color: #1E1E33;
    background-clip: padding-box;
    transition: all 0.15s linear;

    &::before,
    &::after {
      content: '';
      @apply absolute top-0 left-0 right-0 bottom-0 z-[-1] -m-[1px];

      border-radius: inherit;
      transition: opacity 0.3s ease-in-out;

      @media not all and (min-resolution: 0.001dpcm) {
        transition: none;
      }
    }

    &::before {
      opacity: 1;
      background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    }

    &::after {
      opacity: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    }
  }

  & > button[name="prefix"] {
    @apply rounded-l-xl;
  }
  & > button[name="suffix"] {
    @apply rounded-r-xl;
  }
  
  & > div {
    @apply h-[58px] relative bg-[#15152B] m-[1px];

    &::before,
    &::after {
      content: '';
      @apply absolute top-0 left-0 right-0 bottom-0 z-[-1] my-[-1px];

      border-radius: inherit;
      transition: opacity 0.3s ease-in-out;

      @media not all and (min-resolution: 0.001dpcm) {
        transition: none;
      }
    }

    &::before {
      opacity: 1;
      background-image: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1));
    }

    &::after {
      opacity: 0;
      background-image: linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    }

    .input {
      @apply w-full text-center bg-transparent appearance-none px-4 py-4 flex-grow outline-none text-white h-[58px] min-w-[44px];
    }
  }
}
