/* JoinSection styles */
.root {
  will-change: transform;
  transform: translate3d(0, 0, 0);

  @apply relative z-20;
}

.coinImg1 {
  background: url('/assets/images/coin-1.png') no-repeat 50% 50%;
  @apply w-[91px] h-[99px] md:w-[232px] md:h-[238px];
}

.coinImg2 {
  background: url('/assets/images/coin-2.png') no-repeat 50% 50%;
  @apply w-[101px] h-[128px] md:w-[278px] md:h-[285px];
}

.coinImg3 {
  background: url('/assets/images/coin-3.png') no-repeat 50% 50%;
  @apply w-[56px] h-[60px] md:w-[137px] md:h-[140px];
}

.coinImg {
  z-index: -1;

  @apply absolute bg-contain;
}
