/* Faq styles */

.root {
 @apply max-w-[690px] mx-auto;
}

.trigger[data-state='open'] {
  background-image: linear-gradient(180deg, #31E9E6 0%, #0873FB 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  &::before {
    background-image: linear-gradient(180deg, #31E9E6 0%, #0873FB 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.decimalList {
  @apply list-decimal my-4;

  li {
    @apply my-4 ml-8 pl-2;
  }
}

.warning {
  background-color: rgba(255, 174, 0, 0.10);

  @apply flex items-center mt-4 p-4 rounded-xl border border-solid border-amber-050 text-[14px] text-amber-050;
}