/* FlappyBird styles */
.root {
  @apply my-20 sm:my-44 md:my-56 relative;
}

.gameWrapper {
  @apply relative;
}

.image {
  @apply block mb-12 sm:mb-36 text-center w-full;
  @apply mx-auto -mt-5 md:mt-0;

  @apply relative;

  @media (min-width: 960px) {
    @apply w-5/6;
    margin: 50px;

    &::before {
      background-image: url(/assets/images/flappy-bird-game-borders.svg);
      box-sizing: border-box;
      background-size: contain;
      content: ' ';
      background-repeat: no-repeat;
      position: absolute;
      top: -50px;
      left: -50px;
      right: -50px;
      bottom: -50px;
    }
  }

  canvas {
    @apply max-w-full h-auto;
  }


}

.pictureWrapper {
  @apply relative;

  &:hover {
    .pictureButton {
      background-image: url('/assets/images/flappy-bird-game-button-bg-hover.png');
    }
  }
}

.pictureButton {
  background-image: url('/assets/images/flappy-bird-game-button-bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 110px;
  height: 30px;
  bottom: 15%;
  font-family: var(--font-gilroy);
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  pointer-events: none;

  @apply text-white text-xs sm:text-xl font-bold box-border inline-flex justify-center items-center absolute left-2/4 bg-no-repeat;

  @media (min-width: 640px) {
    width: 229px;
    height: 64px;
  }

  @media (min-width: 480px) {
    width: 170px;
    height: 49px;

    @apply text-sm;
  }
}