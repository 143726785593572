.row {
  @apply flex flex-row justify-between -mx-2.5 flex-wrap lg:flex-nowrap gap-y-7 h-full items-center;
  @apply sm:-mb-7;
}

.item {
  @apply w-1/2 px-2 flex flex-col;
}

.itemLabel {
  @apply text-white/[0.6];
  @apply text-sm whitespace-nowrap text-ellipsis overflow-hidden block;
}

.itemValue {
  @apply text-base mt-4 sm:mt-5;
}

.diffChange {
  @apply mx-0.5;
}

.plus {
  @apply text-green-400;
}
