/* InputWithSelect styles */

.inputGroup {
  transition: border 0.15s linear;

  @apply rounded-xl flex w-full justify-between bg-white/[0.08] h-[60px];
  @apply border border-solid border-white/[0.1];

  &:hover {
    @apply border-white/[0.4];

    .suffix {
      @apply border-white/[0.4];
    }
  }

  &:focus-within {
    @apply border-white/[0.6];

    .suffix {
      @apply border-white/[0.6];
    }
  }
}

.suffix {
  @apply border-l border-solid border-white/[0.1];
}

.input {
  @apply bg-transparent border-none appearance-none px-4 py-5 flex-grow w-full outline-none text-white text-base;
}

.selectControl {
  @apply h-[58px] min-h-[58px] #{!important};
}