.abstractTop {
  background: url('/assets/images/support-dnx-abstract-top.svg') 50% 50% no-repeat;
  width: 316px;
  height: 27px;
  z-index: -1;

  @apply hidden lg:inline-block absolute bg-cover;
}

.abstractBottom {
  background: url('/assets/images/support-dnx-abstract-bottom.svg') 50% 50% no-repeat;
  width: 316px;
  height: 27px;
  z-index: -1;

  @apply hidden lg:inline-block absolute bg-cover;
}

.abstractLeft {
  background: url('/assets/images/support-dnx-abstract-left.svg') 50% 50% no-repeat;
  width: 99px;
  height: 117px;
  z-index: -1;

  @apply hidden lg:inline-block absolute bg-cover;
}

.abstractRight {
  background: url('/assets/images/support-dnx-abstract-right.svg') 50% 50% no-repeat;
  width: 98px;
  height: 117px;
  z-index: -1;

  @apply hidden lg:inline-block absolute bg-contain;
}

.blocksWrapper {
  @apply flex flex-wrap gap-6 my-8;
}

.blocks {
  @apply flex flex-col lg:flex-row gap-6 w-full;
}

.block {
  background-image: url('/assets/images/support-dnx-block-bg-100.png');
  background-size: cover;
  background-repeat: no-repeat;

  @apply px-3 sm:px-6 max-w-[405px] h-[90px] flex items-center w-full text-[#F5F5F7] text-xl font-semibold lg:bg-cover border border-solid border-[#2c3047] rounded-2xl;

  &:nth-child(even) {
    background-image: url('/assets/images/support-dnx-block-bg-50.png');
  }

  &.blockShort100,
  &.blockShort50 {
    @apply lg:max-w-[333px];
  }

  &.blockShort100 {
    @media (min-width: 1024px) {
      background-image: url('/assets/images/support-dnx-block-bg-short-100.png');
    }
  }

  &.blockShort50 {
    @media (min-width: 1024px) {
      background-image: url('/assets/images/support-dnx-block-bg-short-50.png');
    }
  }
}

